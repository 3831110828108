import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { register as registerApi, getCurrencies, getCompanyTypes } from '../api/api';
import LanguageSwitcher from '../components/LanguageSwitcher';
import { getUserCurrency } from '../utils/getUserCurrency'; // Mapping ve fonksiyon importu
import { getCurrentLanguage } from '../i18n';

import '../styles/Login.css';

function Register() {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    first_name: '',
    last_name: '',
    name: '',
    address: '',
    phone_number: '',
    website: 'https://',
    currency_id: 2, // API'den gelen para birimleri ile eşleşmeyen varsayılan id, sonrasında güncellenecek.
    company_type_id: 1,
  });
  const [currencies, setCurrencies] = useState([]);
  const [companyTypes, setCompanyTypes] = useState([]);

  const [errors, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [stage, setStage] = useState(1);

  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [currenciesData, companyTypesData] = await Promise.all([
          getCurrencies(),
          getCompanyTypes(),
        ]);
        setCurrencies(currenciesData);
        setCompanyTypes(companyTypesData);

        const defaultCurrencyCode = getUserCurrency(getCurrentLanguage()); 
        const matchingCurrency = currenciesData.find(currency => currency.code === defaultCurrencyCode);
        if (matchingCurrency) {
          setFormData(prevData => ({
            ...prevData,
            currency_id: matchingCurrency.id,
          }));
        }
      } catch (err) {
        setError('Failed to load currencies or company types.');
      }
    };
    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const nextStage = () => setStage((prevStage) => prevStage + 1);
  const prevStage = () => setStage((prevStage) => prevStage - 1);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    
    if (stage === 1) {
      nextStage();
    } else {

      if (formData.password !== formData.password_again) {
        setError({ password_again: [t('passwords_do_not_match')] });
        return;
      }
      
      setLoading(true);

      try {
        await registerApi(formData); // API'ye kayıt isteği gönderiyoruz.
        navigate(`/verify-email?email=${encodeURIComponent(formData.email)}`); // Email doğrulama sayfasına yönlendiriyoruz.
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="login-background">
      <main className="form-signin w-100 m-auto">
        <form onSubmit={handleSubmit}>
          <div className="text-center mb-4">
            <img
              src="/images/logo-black-h60.png"
              alt="Logo"
              className="mb-4"
              style={{ height: '60px', width: 'auto' }}
            />
          </div>
          <h1 className="h3 mb-3 fw-normal">{t('please_register')}</h1>

          {/* Hata mesajları */}
          {errors && errors.non_field_errors && (
            <div className="alert alert-danger" role="alert" aria-live="assertive">
              {errors.non_field_errors.join(', ')}
            </div>
          )}

          {errors?.password_again && (
            <div className="alert alert-danger" role="alert" aria-live="assertive">
              {errors.password_again.join(', ')}
            </div>
          )}

          {stage === 1 && (
            <>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className={`form-control ${errors?.name ? 'is-invalid' : ''}`}
                  id="floatingCompanyName"
                  placeholder={t('company_name')}
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
                <label htmlFor="floatingCompanyName">{t('company_name')}</label>
                {errors?.name && (
                  <div className="invalid-feedback">
                    {errors.name.join(', ')}
                  </div>
                )}
              </div>

              <div className="form-floating mb-3">
                <input
                  type="text"
                  className={`form-control ${errors?.address ? 'is-invalid' : ''}`}
                  id="floatingAddress"
                  placeholder={t('address')}
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  required
                />
                <label htmlFor="floatingAddress">{t('address')}</label>
                {errors?.address && (
                  <div className="invalid-feedback">
                    {errors.address.join(', ')}
                  </div>
                )}
              </div>

              <div className="form-floating mb-3">
                <input
                  type="text"
                  className={`form-control ${errors?.phone_number ? 'is-invalid' : ''}`}
                  id="floatingPhoneNumber"
                  placeholder={t('phone_number')}
                  name="phone_number"
                  value={formData.phone_number}
                  onChange={handleChange}
                  required
                />
                <label htmlFor="floatingPhoneNumber">{t('phone_number')}</label>
                {errors?.phone_number && (
                  <div className="invalid-feedback">
                    {errors.phone_number.join(', ')}
                  </div>
                )}
              </div>

              <div className="form-floating mb-3">
                <input
                  type="url"
                  className={`form-control ${errors?.website ? 'is-invalid' : ''}`}
                  id="floatingWebsite"
                  placeholder={t('website')}
                  name="website"
                  value={formData.website}
                  onChange={handleChange}
                />
                <label htmlFor="floatingWebsite">{t('website')}</label>
                {errors?.website && (
                  <div className="invalid-feedback">
                    {errors.website.join(', ')}
                  </div>
                )}
              </div>

              <div className="form-floating mb-3">
                <select
                  className={`form-control form-select ${errors?.currency_id ? 'is-invalid' : ''}`}
                  id="floatingCurrency"
                  name="currency_id"
                  value={formData.currency_id}
                  onChange={handleChange}
                  required
                >
                  {currencies.map((currency) => (
                    <option key={currency.id} value={currency.id}>
                      {currency.name}
                    </option>
                  ))}
                </select>
                <label htmlFor="floatingCurrency">{t('currency')}</label>
                {errors?.currency_id && (
                  <div className="invalid-feedback">
                    {errors.currency_id.join(', ')}
                  </div>
                )}
              </div>

              <div className="form-floating mb-3">
                <select
                  className={`form-control form-select ${errors?.company_type_id ? 'is-invalid' : ''}`}
                  id="floatingCompanyType"
                  name="company_type_id"
                  value={formData.company_type_id}
                  onChange={handleChange}
                  required
                >
                  {companyTypes.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.name}
                    </option>
                  ))}
                </select>
                <label htmlFor="floatingCompanyType">{t('company_type')}</label>
                {errors?.company_type_id && (
                  <div className="invalid-feedback">
                    {errors.company_type_id.join(', ')}
                  </div>
                )}
              </div>
            </>
          )}

          {stage === 2 && (
            <>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className={`form-control ${errors?.first_name ? 'is-invalid' : ''}`}
                  id="floatingFirstName"
                  placeholder={t('first_name')}
                  name="first_name"
                  value={formData.first_name}
                  onChange={handleChange}
                  required
                />
                <label htmlFor="floatingFirstName">{t('first_name')}</label>
                {errors?.first_name && (
                  <div className="invalid-feedback">
                    {errors.first_name.join(', ')}
                  </div>
                )}
              </div>

              <div className="form-floating mb-3">
                <input
                  type="text"
                  className={`form-control ${errors?.last_name ? 'is-invalid' : ''}`}
                  id="floatingLastName"
                  placeholder={t('last_name')}
                  name="last_name"
                  value={formData.last_name}
                  onChange={handleChange}
                  required
                />
                <label htmlFor="floatingLastName">{t('last_name')}</label>
                {errors?.last_name && (
                  <div className="invalid-feedback">
                    {errors.last_name.join(', ')}
                  </div>
                )}
              </div>

              <div className="form-floating mb-3">
                <input
                  type="email"
                  className={`form-control ${errors?.email ? 'is-invalid' : ''}`}
                  id="floatingEmail"
                  placeholder={t('email')}
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                <label htmlFor="floatingEmail">{t('email')}</label>
                {errors?.email && (
                  <div className="invalid-feedback">
                    {errors.email.join(', ')}
                  </div>
                )}
              </div>

              <div className="form-floating mb-3">
                <input
                  type="password"
                  className={`form-control ${errors?.password ? 'is-invalid' : ''}`}
                  id="floatingPassword"
                  placeholder={t('password')}
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                />
                <label htmlFor="floatingPassword">{t('password')}</label>
                {errors?.password && (
                  <div className="invalid-feedback">
                    {errors.password.join(', ')}
                  </div>
                )}
              </div>

              <div className="form-floating mb-3">
                <input
                  type="password"
                  className={`form-control ${errors?.password_again ? 'is-invalid' : ''}`}
                  id="floatingPasswordAgain"
                  placeholder={t('password_again')}
                  name="password_again"
                  value={formData.password_again}
                  onChange={handleChange}
                  required
                />
                <label htmlFor="floatingPasswordAgain">{t('password_again')}</label>
                {errors?.password_again && (
                  <div className="invalid-feedback">
                    {errors.password_again.join(', ')}
                  </div>
                )}
              </div>
            </>
          )}

          <div className="wizard-nav">
            <button
              className="btn app--button_login w-100 py-2"
              type="submit"
              disabled={loading}
            >
              {loading ? t('loading') : stage === 1 ? t('next') : t('register')}
            </button>
          </div>

          {stage === 1 && (
            <>  
              <div className="divider">
                <span>{t('or')}</span>
              </div>
          
              <p className="text-center mt-3">
                {t('already_have_account')} <Link to="/login" className="app--link">{t('login_here')}</Link>
              </p>
            </>
          )}

          {stage > 1 && (
            <div className="text-center mt-3">
              <button type="button" className="btn btn-link app--previous-link" onClick={prevStage}>
                {t('previous')}
              </button>
            </div>
          )}

          <p className="mt-5 mb-3 text-body-secondary">&copy; 2024</p>
        </form>
        <div className="d-flex justify-content-center mt-3">
          <LanguageSwitcher />
        </div>
      </main>
    </div>
  );
}

export default Register;
