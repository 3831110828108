import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // React Router for navigation
import { getCategories, deleteCategory } from '../api/api'; // API function for categories
import { Modal, Button } from 'react-bootstrap'; // Import Modal and Button from react-bootstrap
import { useTranslation } from 'react-i18next';
import LayoutPageHeader from '../components/common/LayoutPageHeader';
import ButtonCreate from '../components/common/ButtonCreate'; // Import ButtonCreate component
import { formatDate } from '../utils/utils'; // Import formatDate function

import { patchCategory } from '../api/api'; // Import patchCategory function

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState(null);
  // const [sortOrder] = useState('asc');
  const [totalCount, setTotalCount] = useState(0); // Add state for total count

  const navigate = useNavigate(); // Navigation hook
  const { t } = useTranslation();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const data = await getCategories();
        setCategories(data.results.sort((a, b) => a.sort_order - b.sort_order));
        setTotalCount(data.count);
      } catch (err) {
        setError(t('failed_to_load_categories'));
        console.error(err);
      }
    };

    fetchCategories();
  }, [t]);

  const handleCreateCategory = () => {
    navigate('/categories/create'); // Redirect to create category page
  };

  const handleRowClick = (id) => {
    navigate(`/categories/${id}/edit`); // Redirect to edit page for the clicked category
  };

  const handleProductsClick = (id) => {
    navigate(`/categories/${id}/products`); // Redirect to products page for the clicked category
  };

  const handleDeleteCategory = async (id) => {
    try {
      await deleteCategory(id);
      setCategories((prev) => prev.filter((category) => category.id !== id));
      setTotalCount((prevCount) => prevCount - 1); // Update total count
    } catch (err) {
      setError(t('failed_to_delete_category'));
    }
  };

  const handleDeleteClick = (id) => {
    setCategoryToDelete(id);
    setShowModal(true);
  };

  const confirmDelete = async () => {
    if (categoryToDelete) {
      await handleDeleteCategory(categoryToDelete);
      setShowModal(false);
      setCategoryToDelete(null);
    }
  };

  const handleSortOrderChange = async (id, newIndex, e) => {
    e.stopPropagation();
    setCategories(prevCategories => {
      const currentIndex = prevCategories.findIndex(cat => cat.id === id);
      if (currentIndex === -1 || newIndex < 0 || newIndex >= prevCategories.length) return prevCategories;
      const newCategories = [...prevCategories];
      const [moved] = newCategories.splice(currentIndex, 1);
      newCategories.splice(newIndex, 0, moved);
      const updatedCategories = newCategories.map((cat, idx) => ({ ...cat, sort_order: idx }));
      // Update API sort order for categories that changed
      updatedCategories.forEach((cat, idx) => {
        const prev = prevCategories.find(p => p.id === cat.id);
        if (prev && prev.sort_order !== idx) {
          patchCategory(cat.id, { sort_order: idx });
        }
      });
      return updatedCategories;
    });
  };

  return (
    <div className="container py-4">
      <LayoutPageHeader 
        title={t('categories')}
        description={t('categories_description')} 
        buttonElement={
          <ButtonCreate onClick={handleCreateCategory} text={t('create_category')} />
        }
      />

      {error && <div className="alert alert-danger">{error}</div>}

      {/* Display total item size */}
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div>
        </div>
        <div>
          <span>{t('total_items')}: {totalCount}</span>
        </div>
      </div>

      <div className="table-responsive">
        <table className="table table-hover table-sm">
          <thead>
            <tr>
              <th>{t('name')}</th>
              <th>{t('description')}</th>
              <th>{t('sort_order')}</th>
              <th>{t('updated_at')}</th>
              <th>{t('actions')}</th>
            </tr>
          </thead>
          <tbody className="table-group-divider">
            {categories.map((category, index) => (
              <tr
                key={category.id}
                onClick={() => handleRowClick(category.id)}
                style={{ cursor: 'pointer' }} // Add pointer cursor for clarity
              >
                <td>{category.name}</td>
                <td>{category.description}</td>
                <td>
                  <div className="d-flex align-items-center">
                    <button
                      className="btn btn-sm btn-outline-secondary me-1"
                      onClick={(e) => handleSortOrderChange(category.id, index - 1, e)}
                      disabled={index === 0}
                    >
                      ↑
                    </button>
                    {/* Removed category.sort_order display */}
                    <button
                      className="btn btn-sm btn-outline-secondary ms-1"
                      onClick={(e) => handleSortOrderChange(category.id, index + 1, e)}
                      disabled={index === categories.length - 1}
                    >
                      ↓
                    </button>
                  </div>
                </td>
                <td>{formatDate(category.updated_at)}</td>
                <td>
                  <button
                    className="btn btn-secondary btn-sm me-2"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent triggering row click
                      handleProductsClick(category.id);
                    }}
                  >
                    {t('products')}
                  </button>
                  <button
                    className="btn btn-sm btn-outline-danger"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent row click
                      handleDeleteClick(category.id);
                    }}
                  >
                    {t('delete')}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('confirm_deletion')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t('delete_confirmation_category', { categoryName: categories.find(cat => cat.id === categoryToDelete)?.name })}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            {t('cancel')}
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            {t('delete')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Categories;
