import React, { useState, useEffect } from 'react';
import { getCurrencies, getCompany, updateCompany } from '../api/api';
import { useTranslation } from 'react-i18next';
import LayoutPageHeader from '../components/common/LayoutPageHeader';

const Company = () => {
  const { t } = useTranslation();
  const [companyData, setCompanyData] = useState({
    name: '',
    address: '',
    currency_id: '',
    phone_number: '',
    email: '',
    website: '',
  });
  const [currencies, setCurrencies] = useState([]);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const [currencyData, companyData] = await Promise.all([getCurrencies(), getCompany()]);
        setCurrencies(currencyData);
        setCompanyData({
          ...companyData,
          currency_id: companyData.currency.id,
        });
      } catch (error) {
        console.error(t('failed_to_load_company_data'), error);
        setError(t('failed_to_load_company_data'));
      }
    };

    fetchInitialData();
  }, [t]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCompanyData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let updatedData = { ...companyData };
      if (updatedData.website && !/^https?:\/\//i.test(updatedData.website)) {
        updatedData.website = `http://${updatedData.website}`;
      }
      await updateCompany(updatedData);
      setSuccess(t('company_data_updated_successfully'));
      setError(null);
    } catch (error) {
      console.error(t('failed_to_update_company_data'), error);
      setError(t('failed_to_update_company_data'));
      setSuccess(null);
    }
  };

  return (
    <div className="container py-4">
      <LayoutPageHeader title={t('company_information')} />
      
      {error && <div className="alert alert-danger">{error}</div>}
      {success && <div className="alert alert-success">{success}</div>}
      <form onSubmit={handleSubmit}>
        <div className="form-group mb-3">
          <label htmlFor="name">{t('company_name')}</label>
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            value={companyData.name}
            onChange={handleChange}
          />
        </div>
        <div className="form-group mb-3">
          <label htmlFor="address">{t('address')}</label>
          <input
            type="text"
            className="form-control"
            id="address"
            name="address"
            value={companyData.address}
            onChange={handleChange}
          />
        </div>
        <div className="form-group mb-3">
          <label htmlFor="phone_number">{t('phone_number')}</label>
          <input
            type="text"
            className="form-control"
            id="phone_number"
            name="phone_number"
            value={companyData.phone_number}
            onChange={handleChange}
          />
        </div>
        <div className="form-group mb-3">
          <label htmlFor="email">{t('email')}</label>
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            value={companyData.email}
            onChange={handleChange}
          />
        </div>
        <div className="form-group mb-3">
          <label htmlFor="website">{t('website')}</label>
          <input
            type="text"
            className="form-control"
            id="website"
            name="website"
            value={companyData.website}
            onChange={handleChange}
          />
        </div>
        <div className="form-group mb-3">
          <label htmlFor="currency_id">{t('currency')}</label>
          <select
            className="form-control form-select"
            id="currency_id"
            name="currency_id"
            value={companyData.currency_id}
            onChange={handleChange}
          >
            <option value="">{t('select_currency')}</option>
            {currencies.map((currency) => (
              <option key={currency.id} value={currency.id}>
                {currency.name}
              </option>
            ))}
          </select>
        </div>
        <button type="submit" className="btn app--button app--button_blue mt-3">
          {t('update_company')}
        </button>
      </form>
    </div>
  );
};

export default Company;