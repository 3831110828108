import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getCategoryDetails, updateCategory, createCategory } from '../api/api';
import { useTranslation } from 'react-i18next';
import LayoutPageHeader from '../components/common/LayoutPageHeader';


const CategoryForm = ({ isEdit }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [errors, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(isEdit);

  useEffect(() => {
    const fetchCategoryDetails = async () => {
      try {
        const category = await getCategoryDetails(id);
        setName(category.name);
        setDescription(category.description || '');
      } catch (err) {
        setError(t('failed_to_load_category_details'));
      } finally {
        setIsLoading(false);
      }
    };

    if (isEdit) {
      fetchCategoryDetails();
    }
  }, [id, isEdit, t]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccessMessage(null);

    if (!name.trim()) {
      setError(t('please_fill_in_all_required_fields'));
      return;
    }

    const categoryData = {
      name,
      description,
    };

    try {
      if (isEdit) {
        const result = await updateCategory(id, categoryData);
        if(result) { 
          setSuccessMessage(t('category_updated_successfully')); 
          setTimeout(() => navigate('/categories'), 2000);
        } else {
          setError({ non_field_errors: [t('failed_to_update_category')] });
        }
      } else {
        const result = await createCategory(categoryData);
        if (result) {
          setSuccessMessage(t('category_created_successfully'));
          setTimeout(() => navigate('/categories'), 2000);
        } else {
          setError({ non_field_errors: [t('failed_to_create_category')] });
        }
      }
      
    } catch (error) {
      setError(error);
    }
  };

  if (isLoading) {
    return <div className="container py-4">Loading...</div>;
  }

  return (
    <div className="container py-4">

      <LayoutPageHeader title={isEdit ? t('edit_category') : t('create_category')} />

      {/* Error message */}
      {errors && errors.non_field_errors && (
        <div className="alert alert-danger" role="alert" aria-live="assertive">
          {errors.non_field_errors.join(', ')}
        </div>
      )}

      <div className="row">
        <div className="col-xl-6 col-12">
          {successMessage && <div className="alert alert-success">{successMessage}</div>}
          <form onSubmit={handleSubmit}>
            {/* Name */}
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                {t('name')} <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                id="name"
                className={`form-control ${errors?.name ? 'is-invalid' : ''}`}
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              {errors?.name && (
                <div className="invalid-feedback">
                  {errors.name.join(', ')}
                </div>
              )}
            </div>
            {/* Description */}
            <div className="mb-3">
              <label htmlFor="description" className="form-label">
                {t('description')}
              </label>
              <textarea
                id="description"
                className={`form-control ${errors?.description ? 'is-invalid' : ''}`}
                rows="3"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              {errors?.description && (
                <div className="invalid-feedback">
                  {errors.description.join(', ')}
                </div>
              )}
            </div>
            {/* Submit Button */}
            <button type="submit" className="btn btn-sm app--button app--button_blue">
              {isEdit ? t('update') : t('create')}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CategoryForm;