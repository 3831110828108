import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ImageUploadWithPreview from '../components/ImageUploadWithPreview';
import LayoutPageHeader from '../components/common/LayoutPageHeader'; // Import LayoutPageHeader
import { useTranslation } from 'react-i18next'; // Import useTranslation
import { FaPlus, FaCheck, FaTimes } from 'react-icons/fa'; // Added plus, check, and times icon imports

import { 
  getProduct, 
  updateProduct, 
  getIngredients,
  createProduct, 
  getCategories, 
  getProductCategoriesByProductId, 
  createProductCategory, 
  deleteProductCategory, 
  createIngredient 
} from '../api/api';

const ProductForm = ({ isEdit }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation(); // Add translation hook

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [ingredients, setIngredients] = useState([]);
  const [selectedIngredients, setSelectedIngredients] = useState([]);
  const [errors, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(isEdit);
  const [photo, setPhoto] = useState(null);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  // New state variables for adding a new ingredient
  const [showNewIngredientInput, setShowNewIngredientInput] = useState(false);
  const [newIngredientName, setNewIngredientName] = useState('');
  const [newIngredientError, setNewIngredientError] = useState(null);

  useEffect(() => {
    const fetchIngredients = async () => {
      try {
        const data = await getIngredients();
        setIngredients(data.results);
      } catch (err) {
        setError(t('failed_to_load_ingredients'));
      }
    };

    const fetchProductDetails = async () => {
      try {
        const product = await getProduct(id);
        setName(product.name);
        setDescription(product.description || '');
        setPrice(product.price.toString());
        setSelectedIngredients(product.ingredients.map((ingredient) => ingredient.id));
        if (product.photo) {
          setPhoto(product.photo); // Set photo URL if it exists
        }
        const productCategories = await getProductCategoriesByProductId(1000, 1, 'sort_order', id);
        setSelectedCategories(productCategories.results.map((category) => category.category.id));
      } catch (err) {
        setError(t('failed_to_load_product_details'));
      } finally {
        setIsLoading(false);
      }
    };

    const fetchCategories = async () => {
      try {
        const data = await getCategories();
        setCategories(data.results);
      } catch (err) {
        setError(t('failed_to_load_categories'));
      }
    };

    fetchIngredients();
    fetchCategories();
    if (isEdit) {
      fetchProductDetails();
    }
  }, [id, isEdit, t]);

  // New function to handle new ingredient addition
  const handleAddNewIngredient = async () => {
    // Validate ingredient name length (minimum 2 characters)
    if (newIngredientName.trim().length < 2) {
      setNewIngredientError(t('ingredient_name_too_short'));
      return;
    }

    // Check if ingredient already exists (case-insensitive)
    if (ingredients.some((ing) => ing.name.toLowerCase() === newIngredientName.trim().toLowerCase())) {
      setNewIngredientError(t('ingredient_already_exists'));
      return;
    }

    try {
      // Call API to create new ingredient
      const newIngredient = await createIngredient({ name: newIngredientName.trim() });
      // Update ingredients list state with the new ingredient
      setIngredients((prevIngredients) => [...prevIngredients, newIngredient]);
      // Automatically select the new ingredient for the product
      setSelectedIngredients((prev) => [...prev, newIngredient.id]);
      // Reset input and error states, then hide input field
      setNewIngredientName('');
      setNewIngredientError(null);
      setShowNewIngredientInput(false);
    } catch (error) {
      console.error('Error adding new ingredient:', error);
      setNewIngredientError(t('failed_to_add_ingredient'));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccessMessage(null);

    if (!name.trim() || !price.trim()) {
      setError({ 'non_field_errors': [t('please_fill_in_all_required_fields')] });
      return;
    }

    const productData = {
      name,
      description,
      price: parseFloat(price),
      ingredients: selectedIngredients,
    };

    if (isEdit) {
      if (photo === null) {
        productData.photo = null;
      } else if (typeof photo !== 'string') {
        productData.photo = photo;
      }
    } else {
      if (photo) {
        productData.photo = photo;
      }
    }

    try {
      let productId;
      if (isEdit) {
        await updateProduct(id, productData);
        productId = id;
        setSuccessMessage(t('product_updated_successfully'));
      } else {
        const createdProduct = await createProduct(productData);
        productId = createdProduct.id;
        setSuccessMessage(t('product_created_successfully'));
      }

      const existingProductCategories = await getProductCategoriesByProductId(1000, 1, 'sort_order', productId);
      const existingCategoryIds = existingProductCategories.results.map((category) => category.category.id);

      await Promise.all(
        selectedCategories
          .filter((categoryId) => !existingCategoryIds.includes(categoryId))
          .map((categoryId) =>
            createProductCategory({ product: productId, category: categoryId })
          )
      );

      await Promise.all(
        existingCategoryIds
          .filter((categoryId) => !selectedCategories.includes(categoryId))
          .map((categoryId) => {
            const productCategory = existingProductCategories.results.find((pc) => pc.category.id === categoryId);
            return deleteProductCategory(productCategory.id);
          })
      );

      setTimeout(() => navigate('/products'), 2000);
    } catch (error) {
      setError(error);
    }
  };

  const handleIngredientChange = (id) => {
    if (selectedIngredients.includes(id)) {
      setSelectedIngredients((prev) => prev.filter((ingredientId) => ingredientId !== id));
    } else {
      setSelectedIngredients((prev) => [...prev, id]);
    }
  };

  const handleCategoryChange = (id) => {
    if (selectedCategories.includes(id)) {
      setSelectedCategories((prev) => prev.filter((categoryId) => categoryId !== id));
    } else {
      setSelectedCategories((prev) => [...prev, id]);
    }
  };

  if (isLoading) {
    return <div className="container py-4">{t('loading')}</div>;
  }

  return (
    <div className="container py-4">
      <LayoutPageHeader
        title={isEdit ? t('edit_product') : t('create_product')}
      />

      {/* Error message */}
      {errors && errors.non_field_errors && (
        <div className="alert alert-danger" role="alert" aria-live="assertive">
          {errors.non_field_errors.join(', ')}
        </div>
      )}
      
      <div className="row">
        <div className="col-xl-6 col-12">
          {successMessage && <div className="alert alert-success">{successMessage}</div>}
          <form onSubmit={handleSubmit}>
            {/* Name Field */}
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                {t('name')} <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                id="name"
                className={`form-control form-control-sm ${errors?.name ? 'is-invalid' : ''}`}
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              {errors?.name && (
                <div className="invalid-feedback">
                  {errors.name.join(', ')}
                </div>
              )}
            </div>
            {/* Description Field */}
            <div className="mb-3">
              <label htmlFor="description" className="form-label">
                {t('description')}
              </label>
              <textarea
                id="description"
                className={`form-control form-control-sm ${errors?.description ? 'is-invalid' : ''}`}
                rows="3"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              {errors?.description && (
                <div className="invalid-feedback">
                  {errors.description.join(', ')}
                </div>
              )}
            </div>
            {/* Price Field */}
            <div className="mb-3">
              <label htmlFor="price" className="form-label">
                {t('price')} <span className="text-danger">*</span>
              </label>
              <input
                type="number"
                id="price"
                className={`form-control form-control-sm ${errors?.price ? 'is-invalid' : ''}`}
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                required
              />
              {errors?.price && (
                <div className="invalid-feedback">
                  {errors.price.join(', ')}
                </div>
              )}
            </div>
            {/* Photo Upload */}
            <div className="mb-3">
              <label className="form-label">{t('photo')}</label>
              <ImageUploadWithPreview
                onImageUpload={setPhoto}
                initialImage={photo}
              />
              {errors?.photo && (
                <div className="invalid-feedback">
                  {errors.photo.join(', ')}
                </div>
              )}
            </div>
            {/* Ingredients Section */}
            <div className="mb-3">
              <label className="form-label">{t('ingredients')}</label>
              <div className="d-flex flex-wrap">
                {ingredients.map((ingredient) => (
                  <div key={ingredient.id} className="form-check me-3">
                    <input
                      type="checkbox"
                      id={`ingredient-${ingredient.id}`}
                      className="form-check-input"
                      value={ingredient.id}
                      checked={selectedIngredients.includes(ingredient.id)}
                      onChange={() => handleIngredientChange(ingredient.id)}
                    />
                    <label htmlFor={`ingredient-${ingredient.id}`} className="form-check-label">
                      {ingredient.name}
                    </label>
                  </div>
                ))}
              </div>
              <div className="mt-2">
                {/* Toggle button to show new ingredient input */}
                {!showNewIngredientInput && (
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-secondary w-100"
                    onClick={() => setShowNewIngredientInput(true)}
                  >
                    <FaPlus /> {/* replaced text with plus icon */}
                    {t('add_ingredient')}
                  </button>
                )}
                {showNewIngredientInput && (
                  <div className="mt-2">
                    <div className="input-group input-group-sm">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={t('enter_new_ingredient_name')}
                        value={newIngredientName}
                        onChange={(e) => setNewIngredientName(e.target.value)}
                      />
                      <button
                        type="button"
                        className="btn btn-success"
                        onClick={handleAddNewIngredient}
                      >
                        <FaCheck />
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => {
                          setShowNewIngredientInput(false);
                          setNewIngredientName('');
                          setNewIngredientError(null);
                        }}
                      >
                        <FaTimes  />
                      </button>
                    </div>
                    {newIngredientError && (
                      <div className="text-danger mt-1">
                        {newIngredientError}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            {/* Submit Button */}
            {isEdit ? (
              <button
                type="submit"
                className="btn btn-sm app--button app--button_blue"
              >
                {t('update')}
              </button>
            ) : (
              <button
                type="submit"
                className="btn btn-sm app--button app--button_green"
              >
                {t('create')}
              </button>
            )}
          </form>
        </div>
        <div className="col-xl-6 col-12">
          <h5>{t('product_categories')}</h5>
          <p>{t('choose_one_or_more_categories')}</p>
          <div className="d-flex flex-column">
            {categories.map((category) => (
              <div key={category.id} className="form-check mb-2">
                <input
                  type="checkbox"
                  id={`category-${category.id}`}
                  className="form-check-input"
                  value={category.id}
                  checked={selectedCategories.includes(category.id)}
                  onChange={() => handleCategoryChange(category.id)}
                />
                <label htmlFor={`category-${category.id}`} className="form-check-label">
                  {category.name}
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductForm;