import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getQRCodeDetails, updateQRCode, createQRCode, getMenus } from '../api/api';
import { useTranslation } from 'react-i18next'; // Import useTranslation
import LayoutPageHeader from '../components/common/LayoutPageHeader';

const LinkForm = ({ isEdit }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation(); // Add translation hook

  const [menuId, setMenuId] = useState('');
  const [slug, setSlug] = useState('');
  const [description, setDescription] = useState('');
  const [menus, setMenus] = useState([]);
  const [errors, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(isEdit);

  useEffect(() => {
    const fetchQRCodeDetails = async () => {
      try {
        const qrCode = await getQRCodeDetails(id);
        setMenuId(qrCode.menu.id);
        setSlug(qrCode.slug || '');
        setDescription(qrCode.description || '');
      } catch (err) {
        setError('Failed to load QR code details');
      } finally {
        setIsLoading(false);
      }
    };

    const fetchMenus = async () => {
      try {
        const menusData = await getMenus();
        setMenus(menusData.results);
      } catch (err) {
        setError('Failed to load menus');
      }
    };

    if (isEdit) {
      fetchQRCodeDetails();
    }
    fetchMenus();
  }, [id, isEdit]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccessMessage(null);

    if (!menuId || !menuId.toString().trim() || !slug.trim()) {
      setError(t('please_fill_in_all_required_fields')); // Use translation
      return;
    }

    const qrCodeData = {
      menu: menuId,
      slug,
      description,
    };

    try {
      if (isEdit) {
        const result = await updateQRCode(id, qrCodeData);
        if (result) {
          setSuccessMessage(t('link_updated_successfully')); // Use translation
          setTimeout(() => navigate('/links'), 2000);
        }
        
      } else {
        const result = await createQRCode(qrCodeData);
        if (result) {
          setSuccessMessage(t('link_created_successfully')); // Use translation
          setTimeout(() => navigate('/links'), 2000);
        }
      }
      
    } catch (error) {
      setError(error);
    }
  };

  if (isLoading) {
    return <div className="container py-4">{t('loading')}</div>; // Use translation
  }

  return (
    <div className="container py-4">
      <LayoutPageHeader
        title={isEdit ? t('edit_link') : t('create_link')}
      />

      {/* Error message */}
      {errors && errors.non_field_errors && (
        <div className="alert alert-danger" role="alert" aria-live="assertive">
          {errors.non_field_errors.join(', ')}
        </div>
      )}
      
      <div className="row">
        <div className="col-xl-6 col-12">
          
          {successMessage && <div className="alert alert-success">{successMessage}</div>}
          <form onSubmit={handleSubmit}>
            {/* Menu */}
            <div className="mb-3">
              <label htmlFor="menu" className="form-label">
                {t('menu')} <span className="text-danger">*</span> {/* Use translation */}
              </label>
              <select
                id="menu"
                className={`form-control form-select ${errors?.menu_id ? 'is-invalid' : ''}`}
                value={menuId}
                onChange={(e) => setMenuId(e.target.value)}
                required
              >
                <option value="">{t('select_menu')}</option> {/* Use translation */}
                {menus.map((menu) => (
                  <option key={menu.id} value={menu.id}>
                    {menu.name}
                  </option>
                ))}
              </select>
              {errors?.menu_id && (
                <div className="invalid-feedback">
                  {errors.menu_id.join(', ')}
                </div>
              )}
            </div>
            {/* Slug */}
            <div className="mb-3">
              <label htmlFor="slug" className="form-label">
                {t('slug')} <span className="text-danger">*</span> {/* Use translation */}
              </label>
              <input
                type="text"
                id="slug"
                className={`form-control ${errors?.slug ? 'is-invalid' : ''}`}
                value={slug}
                onChange={(e) => setSlug(e.target.value)}
                required
              />
              {errors?.slug && (
                <div className="invalid-feedback">
                  {errors.slug.join(', ')}
                </div>
              )}
            </div>
            {/* Description */}
            <div className="mb-3">
              <label htmlFor="description" className="form-label">
                {t('description')} {/* Use translation */}
              </label>
              <textarea
                id="description"
                className={`form-control ${errors?.description ? 'is-invalid' : ''}`}
                rows="3"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              {errors?.description && (
                <div className="invalid-feedback">
                  {errors.description.join(', ')}
                </div>
              )}
            </div>
            {/* Submit Button */}
            {isEdit ? (
              <button
                type="submit"
                className="btn btn-sm app--button app--button_blue"
              >
                {t('update')} 
              </button>
            ) : (
              <button
                type="submit"
                className="btn btn-sm app--button app--button_green"
              >
                {t('create')} 
              </button>
            )}
            
          </form>
        </div>
      </div>
    </div>
  );
};

export default LinkForm;