import languageCurrencyMapping from "./languageCurrencyMapping";

export const getUserCurrency = (languageCode) => {
  return languageCurrencyMapping[languageCode] || getUserCurrency.DEFAULT_CURRENCY;
}

// Static field
getUserCurrency.DEFAULT_CURRENCY = 'USD';

export default getUserCurrency;




