import { getCurrentLanguage } from '../i18n'; // Import getCurrentLanguage function

export function formatDate(dateString) {
  const date = new Date(dateString);
  if (isNaN(date)) {
    return "Invalid Date";
  }

  const locale = getCurrentLanguage(); // Get the current language
  
  // Format: year, month, day, hour, minute, second
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    // second: "2-digit"
  };

  return date.toLocaleString(locale, options);
}