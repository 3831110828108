import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getQRCodes, deleteQRCode } from '../api/api';
import { Modal, Button, Toast } from 'react-bootstrap';
import { FaExternalLinkAlt, FaCopy } from 'react-icons/fa'; // Import icons from react-icons
import { useTranslation } from 'react-i18next'; // Import useTranslation
import LayoutPageHeader from '../components/common/LayoutPageHeader';
import ButtonCreate from '../components/common/ButtonCreate'; // Import ButtonCreate component
import QRCodePreview from '../components/QRCodePreview';
import { formatDate } from '../utils/utils'; // Import formatDate function

const Links = () => {
  const [qrCodes, setQRCodes] = useState([]);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedQRCode, setSelectedQRCode] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [showQRCodeModal, setShowQRCodeModal] = useState(false);
  const [qrCodeToShow, setQRCodeToShow] = useState(null);
  const [totalCount, setTotalCount] = useState(0); // Add state for total count
  const navigate = useNavigate();
  const { t } = useTranslation(); // Add translation hook

  useEffect(() => {
    const fetchQRCodes = async () => {
      try {
        const data = await getQRCodes();
        setQRCodes(data.results);
        setTotalCount(data.count); // Set total count
      } catch (err) {
        setError('Failed to load QR codes');
        console.error(err);
      }
    };

    fetchQRCodes();
  }, []);

  const handleCreateQRCode = () => {
    navigate('/links/create');
  };

  const handleEditQRCode = (id) => {
    navigate(`/links/${id}/edit`);
  };

  const handleDeleteQRCode = async () => {
    try {
      await deleteQRCode(selectedQRCode.id);
      setQRCodes(qrCodes.filter(qrCode => qrCode.id !== selectedQRCode.id));
      setTotalCount((prevCount) => prevCount - 1); // Update total count
      setShowModal(false);
    } catch (err) {
      setError('Failed to delete link');
      console.error(err);
    }
  };

  const openModal = (qrCode) => {
    setSelectedQRCode(qrCode);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedQRCode(null);
  };

  const openQRCodeModal = (slug) => {
    const link = `https://menu.justqrmenu.com/${slug}`;
    setQRCodeToShow(link);
    setShowQRCodeModal(true);
  };

  const closeQRCodeModal = () => {
    setShowQRCodeModal(false);
    setQRCodeToShow(null);
  };

  const handleCopyLink = (slug) => {
    const link = `https://menu.justqrmenu.com/${slug}`;
    navigator.clipboard.writeText(link).then(() => {
      setShowToast(true);
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  };

  const handleOpenLink = (slug) => {
    const link = `https://menu.justqrmenu.com/${slug}`;
    window.open(link, '_blank');
  };

  return (
    <div className="container py-4">
      <LayoutPageHeader
        title={t('links')}
        description={t('links_description')}
        buttonElement={
          <ButtonCreate onClick={handleCreateQRCode} text={t('create_link')} />
        }
      />

      {error && <div className="alert alert-danger">{error}</div>}

      {/* Display total item size */}
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div>
        </div>
        <div>
          <span>{t('total_items')}: {totalCount}</span>
        </div>
      </div>

      <div className="table-responsive">
        <table className="table table-hover table-sm">
          <thead>
            <tr>
              <th>{t('menu_name')}</th>
              <th>{t('slug')}</th>
              <th>{t('description')}</th>
              <th>{t('actions')}</th>
            </tr>
          </thead>
          <tbody className="table-group-divider">
            {qrCodes.map((qrCode) => (
              <tr key={qrCode.id} style={{ cursor: 'pointer' }} onClick={() => handleEditQRCode(qrCode.id)}>
                <td>{qrCode.menu.name}</td>
                <td>
                  {qrCode.slug}
                  <i
                    className="bi bi-clipboard-check ms-2"
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCopyLink(qrCode.slug);
                    }}
                    onMouseEnter={(e) => e.currentTarget.style.color = 'green'}
                    onMouseLeave={(e) => e.currentTarget.style.color = ''} // or 'inherit'
                  >
                    <FaCopy />
                  </i>
                  <i
                    className="bi bi-box-arrow-up-right ms-2"
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleOpenLink(qrCode.slug);
                    }}
                    onMouseEnter={(e) => e.currentTarget.style.color = 'blue'}
                    onMouseLeave={(e) => e.currentTarget.style.color = ''} // or 'inherit'
                  >
                    <FaExternalLinkAlt />
                  </i>
                </td>
                <td>{qrCode.description}</td>
                <td>{formatDate(qrCode.updated_at)}</td>
                <td>
                  <button
                    className="btn btn-sm btn-outline-primary me-2"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEditQRCode(qrCode.id);
                    }}
                  >
                    {t('edit')}
                  </button>
                  <button
                    className="btn btn-sm btn-outline-danger me-2"
                    onClick={(e) => {
                      e.stopPropagation();
                      openModal(qrCode);
                    }}
                  >
                    {t('delete')}
                  </button>
                  <button
                    className="btn btn-sm btn-outline-info"
                    onClick={(e) => {
                      e.stopPropagation();
                      openQRCodeModal(qrCode.slug);
                    }}
                  >
                    {t('show_qr')}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t('confirm_deletion')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t('delete_confirmation_link', { linkName: selectedQRCode?.menu.name })}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            {t('cancel')}
          </Button>
          <Button variant="danger" onClick={handleDeleteQRCode}>
            {t('delete')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showQRCodeModal} onHide={closeQRCodeModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t('qr_code')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {qrCodeToShow && <QRCodePreview qrData={qrCodeToShow} />}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeQRCodeModal}>
            {t('close')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Toast
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={3000}
        autohide
        style={{ position: 'fixed', bottom: '20px', right: '20px' }}
      >
        <Toast.Body>{t('link_copied')}</Toast.Body>
      </Toast>
    </div>
  );
};

export default Links;