import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getMenuDetails, updateMenu, createMenu, getCategories } from '../api/api';
import { FaTimesCircle, FaPlusCircle } from 'react-icons/fa'; // Import icons from react-icons
import { useTranslation } from 'react-i18next'; // Import useTranslation
import LayoutPageHeader from '../components/common/LayoutPageHeader';


const MenuForm = ({ isEdit }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation(); // Add translation hook

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [categories, setCategories] = useState([]);
  const [menuCategories, setMenuCategories] = useState([]);
  const [errors, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(isEdit);

  useEffect(() => {
    const fetchMenuDetails = async () => {
      try {
        const menu = await getMenuDetails(id);
        setName(menu.name);
        setDescription(menu.description || '');
        setMenuCategories(menu.categories || []);
      } catch (err) {
        setError(t('failed_to_load_menu_details')); // Use translation for error message
      } finally {
        setIsLoading(false);
      }
    };

    const fetchCategories = async () => {
      try {
        const categoriesData = await getCategories();
        setCategories(categoriesData.results);
      } catch (err) {
        setError(t('failed_to_load_categories')); // Use translation for error message
      }
    };

    if (isEdit) {
      fetchMenuDetails();
    }
    fetchCategories();
  }, [id, isEdit, t]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccessMessage(null);

    if (!name.trim()) {
      setError(t('please_fill_in_all_required_fields')); // Use translation for error message
      return;
    }

    const menuData = {
      name,
      description,
      categories: menuCategories.map(category => category.id),
    };

    try {
      if (isEdit) {
        const result = await updateMenu(id, menuData);
        if (result) {
          setSuccessMessage(t('menu_updated_successfully')); // Use translation for success message
          setTimeout(() => navigate('/menus'), 2000);
        }
        
      } else {
        const result = await createMenu(menuData);
        if (result) {
          setSuccessMessage(t('menu_created_successfully')); // Use translation for success message
          setTimeout(() => navigate('/menus'), 2000);
        }
      }
      
    } catch (error) {
      setError(error);
    }
  };

  const handleToggleCategory = (categoryId) => {
    if (menuCategories.some(category => category.id === categoryId)) {
      setMenuCategories(menuCategories.filter(category => category.id !== categoryId));
    } else {
      const categoryToAdd = categories.find(category => category.id === categoryId);
      setMenuCategories([...menuCategories, categoryToAdd]);
    }
  };

  if (isLoading) {
    return <div className="container py-4">{t('loading')}</div>; // Use translation for loading text
  }

  return (
    <div className="container py-4">
      <LayoutPageHeader
        title={isEdit ? t('edit_menu') : t('create_menu')}
      />

      {/* Error message */}
      {errors && errors.non_field_errors && (
        <div className="alert alert-danger" role="alert" aria-live="assertive">
          {errors.non_field_errors.join(', ')}
        </div>
      )}
      
      <div className="row">
        <div className="col-xl-6 col-12">
          
          {successMessage && <div className="alert alert-success">{successMessage}</div>}
          <form onSubmit={handleSubmit}>
            {/* Name */}
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                {t('name')} <span className="text-danger">*</span> {/* Use translation for name label */}
              </label>
              <input
                type="text"
                id="name"
                className={`form-control ${errors?.name ? 'is-invalid' : ''}`}
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              {errors?.name && (
                <div className="invalid-feedback">
                  {errors.name.join(', ')}
                </div>
              )}
            </div>
            {/* Description */}
            <div className="mb-3">
              <label htmlFor="description" className="form-label">
                {t('description')} {/* Use translation for description label */}
              </label>
              <textarea
                id="description"
                className={`form-control ${errors?.description ? 'is-invalid' : ''}`}
                rows="3"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              {errors?.description && (
                <div className="invalid-feedback">
                  {errors.description.join(', ')}
                </div>
              )}
            </div>
            {/* Submit Button */}
            {isEdit ? (
              <button
                type="submit"
                className="btn btn-sm app--button app--button_blue"
              >
                {t('update')} 
              </button>
            ) : (
              <button
                type="submit"
                className="btn btn-sm app--button app--button_green"
              >
                {t('create')} 
              </button>
            )}
          </form>
        </div>
        <div className="col-xl-6 col-12">
          <div className="card">
            <div className="card-header">{t('select_categories')}</div> {/* Use translation for card header */}
            <div className="card-body">
              <p>{t('select_categories_info')}</p> {/* Use translation for card body text */}
              <ul className="list-group">
                {categories.map((category) => (
                  <li
                    key={category.id}
                    className={`list-group-item d-flex justify-content-between align-items-center ${menuCategories.some(menuCategory => menuCategory.id === category.id) ? 'active' : ''}`}
                    onClick={() => handleToggleCategory(category.id)}
                    style={{ cursor: 'pointer' }}
                  >
                    {category.name}
                    <i className={`bi ${menuCategories.some(menuCategory => menuCategory.id === category.id) ? 'bi-x-circle-fill text-white' : 'bi-plus-circle-fill text-success'}`}>
                      {menuCategories.some(menuCategory => menuCategory.id === category.id) ? <FaTimesCircle /> : <FaPlusCircle />}
                    </i>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuForm;